// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { LIST_STORE } from 'src/store-admin/constants';
import { BREADCUMBS } from 'src/common/constants/common.constants';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  coin: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  setting: getIcon('ic_menu_item'),
  policy: getIcon('ic_policy'),
  document: getIcon('ic_policy'),
  userManage: <Iconify icon="mdi:user-box" />,
  floatingButton: <Iconify icon={'teenyicons:button-outline'} />,
  campaign: <Iconify icon="mdi:push-notification-outline" />,
  banner: <Iconify icon={'icon-park-outline:tent-banner'} />,
  gift: getIcon('ic_gift'),
  filter: getIcon('ic_policy'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'shop-invitation',
  //       path: PATH_DASHBOARD.general.shop_invitation,
  //       icon: ICONS.analytics,
  //     },
  //   ],
  // },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: BREADCUMBS.GIFT_MANAGE,
        path: PATH_DASHBOARD.giftAdmin.root,
        icon: ICONS.gift,
        children: [
          { title: BREADCUMBS.GIFT_LIST, path: PATH_DASHBOARD.giftAdmin.list },
          {
            title: BREADCUMBS.GIFT_CATEGORY,
            path: PATH_DASHBOARD.giftAdmin.categoryList,
          },
          {
            title: BREADCUMBS.GIFT_PRE_ORDER,
            path: PATH_DASHBOARD.giftAdmin.preOrderList,
          },
        ],
      },

      {
        title: BREADCUMBS.USER_GIFT_MANAGE,
        path: PATH_DASHBOARD.userGiftAdmin.root,
        icon: ICONS.gift,
      },

      // ORDER
      {
        title: BREADCUMBS.ORDER_MANAGE,
        path: PATH_DASHBOARD.orderAdmin.root,
        icon: ICONS.cart,
        children: [
          { title: BREADCUMBS.ORDER_LIST, path: PATH_DASHBOARD.orderAdmin.root },
        ],
      },
      // COIN

      {
        title: BREADCUMBS.COIN_MANAGE,
        path: PATH_DASHBOARD.coinAdmin.root,
        icon: ICONS.banking,
        children: [{ title: BREADCUMBS.COIN_LIST, path: PATH_DASHBOARD.coinAdmin.root }],
      },

      // IDENTITY POINT IDENTITY_POINT_MANAGE
      // {
      //   title: BREADCUMBS.IDENTITY_POINT_HISTORY,
      //   path: PATH_DASHBOARD.identityPointAdmin.root,
      //   icon: ICONS.blog,
      //   children: [
      //     {
      //       title: BREADCUMBS.IDENTITY_POINT_HISTORY,
      //       path: PATH_DASHBOARD.identityPointAdmin.root,
      //     },
      //   ],
      // },
      // STORE
      {
        title: BREADCUMBS.STORE_ADMIN,
        path: PATH_DASHBOARD.storeAdmin.root,
        icon: ICONS.booking,
        children: [
          {
            title: LIST_STORE,
            path: PATH_DASHBOARD.storeAdmin.list,
          },
        ],
      },
      // EVENT
      // {
      //   title: 'Sự kiện Promotion Quý 4',
      //   path: PATH_DASHBOARD.eventAdmin.root,
      //   icon: ICONS.calendar,
      //   children: [
      //     {
      //       title: 'Danh sách Event',
      //       path: PATH_DASHBOARD.eventPromotionIV.list,
      //     },
      //     {
      //       title: 'Lịch sử trúng giải',
      //       path: PATH_DASHBOARD.eventAdmin.historyPrize,
      //     },
      //   ],
      // },
      {
        title: 'Quản lý quản trị viên',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.policy,
        children: [{ title: 'Danh sách quản trị viên', path: PATH_DASHBOARD.admin.list }],
      },
      {
        title: BREADCUMBS.MANAGE_EVENT,
        path: PATH_DASHBOARD.manageEventQuarterOne.root,
        icon: ICONS.cart,
        children: [
          {
            title: BREADCUMBS.MANAGE_LIST_EVENT,
            path: PATH_DASHBOARD.manageEventQuarterOne.list,
          },
          {
            title: BREADCUMBS.EVENT_ADD_CAN,
            path: PATH_DASHBOARD.eventAddCan.list,
          },
          {
            title: BREADCUMBS.EVENT_POPUP_PRIORITY,
            path: PATH_DASHBOARD.EventCommonPopupPriority.list,
          },
        ],
      },
      {
        title: BREADCUMBS.MANAGE_GROUP_EVENT,
        path: PATH_DASHBOARD.eventQ1GroupEvent.root,
        icon: ICONS.policy,
        children: [
          {
            title: BREADCUMBS.LIST_GROUP_EVENT,
            path: PATH_DASHBOARD.eventQ1GroupEvent.listGroupEvent,
          },
        ],
      },
      {
        title: BREADCUMBS.EVENT_HISTORY_PRIZE,
        path: PATH_DASHBOARD.eventHistoryPrize.listHistoryPrize,
        icon: ICONS.calendar,
      },
      {
        title: BREADCUMBS.EVENT_HISTORY_PRIZE_V2,
        path: PATH_DASHBOARD.eventHistoryPrizeV2.listHistoryPrize,
        icon: ICONS.calendar,
      },
      {
        title: BREADCUMBS.CAMPAIGN_MANAGE,
        path: PATH_DASHBOARD.campaign_manage.root,
        icon: ICONS.campaign,
        children: [
          {
            title: BREADCUMBS.CAMPAIGN_LIST,
            path: PATH_DASHBOARD.campaign_manage.list,
          },
          {
            title: BREADCUMBS.CAMPAIGN_CREATE,
            path: PATH_DASHBOARD.campaign_manage.create,
          },
          {
            title: BREADCUMBS.LIST_GROUP_USER,
            path: PATH_DASHBOARD.campaign_manage.listGroupUser,
          },
          {
            title: BREADCUMBS.GROUP_TOPIC_LIST,
            path: PATH_DASHBOARD.campaign_manage.groupTopic.list,
          },
        ],
      },
      {
        title: BREADCUMBS.FLOATING_BUTTON_MANAGE,
        path: PATH_DASHBOARD.floatingButton.root,
        icon: ICONS.floatingButton,
      },
      {
        title: BREADCUMBS.BANNER_MANAGE,
        path: PATH_DASHBOARD.banner_manage.root,
        icon: ICONS.banner,
        children: [
          {
            title: BREADCUMBS.BANNER_LIST,
            path: PATH_DASHBOARD.banner_manage.list,
          },
          {
            title: BREADCUMBS.BANNER_CREATE,
            path: PATH_DASHBOARD.banner_manage.create,
          },
        ],
      },
      // FILTER
      {
        title: BREADCUMBS.FILTER_MANAGE,
        path: PATH_DASHBOARD.filterAdmin.root,
        icon: ICONS.gift,
      },
      // HIDDEN_TAG
      {
        title: BREADCUMBS.HIDDEN_TAG_MANAGE,
        path: PATH_DASHBOARD.hiddenTagsAdmin.root,
        icon: ICONS.gift,
      },
      // WEBAPP
      {
        title: BREADCUMBS.WEBAPP_MANAGE,
        path: PATH_DASHBOARD.webappManange.root,
        icon: ICONS.document,
        children: [
          {
            title: BREADCUMBS.WEBAPP_GUIDELINE_LIST,
            path: PATH_DASHBOARD.webappManange.guideline,
          },
          {
            title: BREADCUMBS.WEBAPP_LINK_RULE_LIST,
            path: PATH_DASHBOARD.webappManange.linkRule,
          },
        ],
      },
      // MANAGE WEBVIEW
      {
        title: BREADCUMBS.WEBVIEW_MANAGE,
        path: PATH_DASHBOARD.webviewManage.root,
        icon: ICONS.banner,
        children: [
          {
            title: BREADCUMBS.WEBVIEW_MANAGE_LIST,
            path: PATH_DASHBOARD.webviewManage.list,
          },
        ],
      },
      // MANAGE CRM TRANSACTION TYPE
      {
        title: BREADCUMBS.CRM_TRANSACTION_TYPE,
        path: PATH_DASHBOARD.crmTransactionType.root,
        icon: ICONS.userManage,
        children: [
          {
            title: BREADCUMBS.CRM_TRANSACTION_TYPE_LIST,
            path: PATH_DASHBOARD.crmTransactionType.list,
          },
        ],
      },
    ],
  },
];

export default navConfig;
