import { Lang, LangObj } from './common.interfaces';

export const LANG: Record<Lang, Lang> = {
  en: 'en',
  vi: 'vi',
};

export const langs: Record<Lang, LangObj> = {
  en: {
    label: 'English',
    value: 'en',
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  vi: {
    label: 'Vietnamese',
    value: 'vi',
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
};
export const DATE_FORMAT = 'dd.MM.yyyy';

export enum BooleanEnum {
  TRUE = 1,
  FALSE = -1,
}

export const FIELD = {
  WOOD: 'wood',
};

export const BREADCUMBS = {
  DASHBOARD: 'Trang chủ',
  LIST_EVENT: 'Sự kiện Promotion quý 4',
  CREATE_EVENT: 'Tạo mới sự kiện',
  VIEW_EVENT: 'Xem sự kiện',
  LIST_REGISTER_EVENT: 'List register event',
  LIST_POLICY_CATEGORY: 'List category policy',
  ADD_POLICY_CATEGORY: 'Add category',
  LIST_ENTERPRISE_CATEGORY: 'List category enterprise',
  CATEGORY_ENTERPRISE_LIST: 'List category enterprise ',
  CATEGORY_ENTERPRISE_EDIT: 'Category enterprise edit',
  CATEGORY_ENTERPRISE_NEW: 'category enterprise add new',
  SHOP_INVITATION: 'shop invitation',
  SHOP_INVITATION_lIST: 'shop invitation list',
  ADMIN_LIST: 'List admin',
  STORE_ADMIN: 'Cửa hàng định danh',

  EVENT_PROMOTION_Q4: 'Sự kiện Promotion Quý 4',

  NEW_ADMIN: 'create admin',
  EDIT_EVENT_PRIZE: 'Chỉnh sửa quà tặng sự kiện',
  LIST_EVENT_PRIZE: 'Danh sách quà tặng sự kiện',

  MANAGE_EVENT: 'Quản lý sự kiện',
  MANAGE_LIST_EVENT: 'Sự kiện trúng thưởng',
  MANAGE_CREATE_EVENT: 'Tạo mới sự kiện',
  MANAGE_EDIT_EVENT: 'Chỉnh sửa sự kiện',
  MANAGE_VIEW_EVENT: 'Thông tin sự kiện',

  EVENT_Q1: 'Sự kiện quý 1',
  EVENT_PRIZE: 'Giải thưởng sự kiện',
  EVENT_PRIZE_LIST: 'Danh sách giải',
  EVENT_PRIZE_CREATE: 'Tạo mới giải',
  EVENT_PRIZE_EDIT: 'Chỉnh sửa giải',
  EVENT_PRIZE_DETAIL: 'Thông tin giải',

  MANAGE_GROUP_EVENT: 'Quản lý Group Event',
  LIST_GROUP_EVENT: 'Danh sách Group Event',
  CREATE_GROUP_EVENT: 'Tạo Group Event',
  EDIT_GROUP_EVENT: 'Chỉnh sửa Group Event',
  VIEW_GROUP_EVENT: 'Chi tiết Group Event',

  EVENT_HISTORY_PRIZE: 'Lịch sử trúng giải',
  EVENT_HISTORY_PRIZE_V2: 'Lịch sử trúng giải (NEW)',
  EVENT_PROMOTION_Q1: 'Sự kiện Promotion Quý 1',

  EVENT_ADD_CAN: 'Sự kiện đổi quà',
  EVENT_ADD_CAN_LIST: 'Danh sách Sự kiện đổi quà',
  EVENT_ADD_CAN_CREATE: 'Tạo Sự kiện đổi quà',
  EVENT_ADD_CAN_EDIT: 'Chỉnh sửa Sự kiện đổi quà',
  EVENT_ADD_CAN_DETAIL: 'Chi tiết Sự kiện đổi quà',

  EVENT_POPUP_PRIORITY: 'Ưu tiên Popup',
  EVENT_POPUP_PRIORITY_LIST: 'Danh sách Ưu tiên Popup',

  EVENT_CAN_MARK_LIST: 'Danh sách quà',
  EVENT_CAN_MARK_CREATE: 'Tạo quà',
  EVENT_CAN_MARK_EDIT: 'Chỉnh sửa quà',
  EVENT_CAN_MARK_DETAIL: 'Chi tiết quà',

  EVENT_ADD_CAN_POPUP_LIST: 'Danh sách popup',
  EVENT_ADD_CAN_POPUP_CREATE: 'Tạo popup',
  EVENT_ADD_CAN_POPUP_EDIT: 'Chỉnh sửa popup',
  EVENT_ADD_CAN_POPUP_DETAIL: 'Chi tiết popup',

  EVENT_ADD_CAN_WEBVIEW_LIST: 'Danh sách webview',
  EVENT_ADD_CAN_WEBVIEW_CREATE: 'Tạo webview',
  EVENT_ADD_CAN_WEBVIEW_EDIT: 'Chỉnh sửa webview',
  EVENT_ADD_CAN_WEBVIEW_DETAIL: 'Chi tiết webview',

  EVENT_WEBVIEW_LIST: 'Danh sách webview',
  EVENT_WEBVIEW_CREATE: 'Tạo webview',
  EVENT_WEBVIEW_EDIT: 'Chỉnh sửa webview',
  EVENT_WEBVIEW_DETAIL: 'Chi tiết webview',

  EVENT_POPUP_LIST: 'Danh sách popup',
  EVENT_POPUP_CREATE: 'Tạo popup',
  EVENT_POPUP_EDIT: 'Chỉnh sửa popup',
  EVENT_POPUP_DETAIL: 'Chi tiết popup',

  CAMPAIGN_MANAGE: 'Quản lý chiến dịch',
  CAMPAIGN_LIST: 'Danh sách chiến dịch',
  CAMPAIGN_CREATE: 'Tạo mới chiến dịch',
  CAMPAIGN_DETAIL: 'Chi tiết chiến dịch',
  CAMPAIGN_EDIT: 'Chỉnh sửa chiến dịch',
  LIST_GROUP_USER: 'Danh sách nhóm người dùng',
  FLOATING_BUTTON_MANAGE: 'Quản lý floating',

  GROUP_TOPIC_MANAGE: 'Quản lý Group Topic',
  GROUP_TOPIC_LIST: 'Danh sách Group Topic',
  GROUP_TOPIC_CREATE: 'Tạo mới Group Topic',
  GROUP_TOPIC_DETAIL: 'Chi tiết Group Topic',
  GROUP_TOPIC_EDIT: 'Chỉnh sửa Group Topic',

  BANNER_MANAGE: 'Quản lý banner',
  BANNER_LIST: 'Danh sách banner',
  BANNER_CREATE: 'Tạo mới banner',
  BANNER_EDIT: 'Chỉnh sửa banner',

  GIFT_MANAGE: 'Quản lý quà',
  GIFT_LIST: 'Danh sách quà',
  GIFT_ORDER_LIST: 'Danh sách quà đặt trước',
  GIFT_CREATE: 'Tạo quà',
  GIFT_EDIT: 'Chỉnh sửa quà',

  USER_GIFT_MANAGE: 'Quản lý User Gift',
  USER_GIFT_LIST: 'Danh sách User Gift',

  ORDER_MANAGE: 'Quản lý đơn hàng',
  ORDER_LIST: 'Danh sách đơn hàng',
  ORDER_DETAIL: 'Chi tiết quà',
  ORDER_SHOW_SHIP: 'Chỉnh sửa ẩn/hiện phí ship',

  COIN_MANAGE: 'Quản lý hoàn xu',
  COIN_LIST: 'Danh sách hoàn xu',
  COIN_DETAIL: 'Chi tiết hoàn xu',
  COIN_EDIT: 'Chỉnh sửa hoàn xu',

  // identity point
  IDENTITY_POINT_MANAGE: 'Quản lý giao dịch',
  IDENTITY_POINT_HISTORY: 'Lịch sử biến động số dư',
  IDENTITY_POINT_DETAIL: 'Chi tiết giao dịch theo người dùng',

  GIFT_CATEGORY: 'Danh sách danh mục',
  GIFT_CATEGORY_CREATE: 'Tạo danh mục',
  GIFT_CATEGORY_EDIT: 'Chỉnh sửa danh mục',

  FILTER_MANAGE: 'Quản lý bộ lọc',
  FILTER_CREATE: 'Tạo mới bộ lọc',
  FILTER_DETAIL: 'Chi tiết bộ lọc',
  FILTER_EDIT: 'Chỉnh sửa bộ lọc',

  HIDDEN_TAG_MANAGE: 'Quản lý Hidden user tag',
  HIDDEN_TAG_CREATE: 'Tạo Hidden User Tag',
  HIDDEN_TAG_EDIT: 'Chỉnh sửa Hidden User Tag',

  GIFT_PRE_ORDER: 'Danh sách quà đặt trước',

  WEBAPP_MANAGE: 'Quản lý Webapp',
  WEBAPP_GUIDELINE_LIST: 'Danh sách hướng dẫn',
  WEBAPP_GUIDELINE_CREATE: 'Tạo mới hướng dẫn',
  WEBAPP_GUIDELINE_EDIT: 'Chỉnh sửa hướng dẫn',
  WEBAPP_LINK_RULE_LIST: 'Danh sách thể lệ CT',
  WEBAPP_LINK_RULE_CREATE: 'Tạo mới thể lệ CT',
  WEBAPP_LINK_RULE_EDIT: 'Chỉnh sửa thể lệ CT',

  WEBVIEW_MANAGE: 'Quản lý webview',
  WEBVIEW_MANAGE_LIST: 'Danh sách webview',
  WEBVIEW_MANAGE_CREATE: 'Tạo mới webview',
  WEBVIEW_MANAGE_EDIT: 'Chỉnh sửa webview',
  WEBVIEW_MANAGE_DETAIL: 'Thông tin webview',

  CRM_TRANSACTION_TYPE: 'Quản lý CRM Transaction Type',
  CRM_TRANSACTION_TYPE_LIST: 'Danh sách CRM Transaction Type',
  CRM_TRANSACTION_TYPE_CREATE: 'Tạo mới CRM Transaction Type',
  CRM_TRANSACTION_TYPE_EDIT: 'Chỉnh sửa CRM Transaction Type',
  CRM_TRANSACTION_TYPE_DETAIL: 'Thông tin CRM Transaction Type',
};

export const CACHE_TIME = 2 * 1000 * 60;

export const FORMAT_DATE_FILTER = 'MM-DD-YYYY HH:mm:ss';

export const DATE_FORMAT_VN = 'DD-MM-YYYY HH:mm:ss';

export const FORMAT_DATE_NEWS = 'dd-MM-yyyy HH:mm:ss';
export const FORMAT_DATE = 'dd-MM-yyyy HH:mm:ss';

export const LIST_ROUTE = [
  { route: '/my-reward?tab=gift', name: 'Quà tặng của tôi' },
  { route: '/my-reward?tab=e-voucher', name: 'E-voucher của tôi' },
  { route: '/mini-game', name: 'Giải trí' },
  { route: '/notifications', name: 'Danh mục thông báo' },
  { route: '/rewards', name: 'Danh sách quà tặng' },
  { route: '/history?tab=add-point', name: 'Lịch sử tích điểm' },
  { route: '/my-point', name: 'Điểm của tôi' },
  { route: '/home', name: 'Trang chủ' },
  { route: '/change-password', name: 'Đổi mật khẩu' },
  { route: '/add-address', name: 'Thêm địa chỉ' },
  { route: '/chat-bot', name: 'Chat bot' },
  { route: '/delivery-list', name: 'Đơn quà của tôi' },
  { route: '/share', name: 'Chia sẻ bạn bè' },
  { route: '/change-info', name: 'Chỉnh sửa hồ sơ' },
  { route: '/contact', name: 'Liên hệ' },
];

export const IMPORT_OPTION = {
  UPDATE_BILL: 'Cập nhật MVĐ',
  UPDATE_ORDER: 'Đơn giao lại toàn phần',
  UPDATE_ORDER_PART: 'Đơn giao lại 1 phần',
  UPDATE_SL: 'Cập nhật SL qua G1P',
  UPDATE_DELIVERY_PROVIDER: 'Cập nhật TT ĐVVD',
  IMPORT_COIN_LIST: 'Nhập danh sách hoàn xu',
};

export const validTypeImage = ['jpeg', 'jpg', 'png'];
export const helperText = {
  required: 'Trường này không được để trống',
  min: 'Trường này không được nhỏ hơn 0',
  min1: 'Trường này không được nhỏ hơn 1',
};

export const UNIVERSAL_LINK = 'https://vitadairy.page.link/main';

export const BRAND_OPTIONS = [
  { label: 'OGGI', value: 'OGGI' },
  { label: 'COLOS DHA+', value: 'COLOS DHA+' },
  { label: 'COLOSIGG', value: 'COLOSIGG' },
  { label: 'NEPRO', value: 'NEPRO' },
  { label: 'GLUVITA', value: 'GLUVITA' },
  { label: 'COLOSGAIN', value: 'COLOSGAIN' },
  { label: 'CALOKID', value: 'CALOKID' },
  { label: 'COLOSBABY', value: 'COLOSBABY' },
  { label: 'GOATLAC', value: 'GOATLAC' },
  { label: 'CALOSURE', value: 'CALOSURE' },
  { label: 'COLOSOPTI', value: 'COLOSOPTI' },
];

export const IMPROVEMENT_EVENT_PHASE_1 = {
  DATE_TIME: new Date('2025-01-01T00:00:00Z'),
  LATEST_EVENT_ID: 621,
  LATEST_EVENT_ADD_CAN_ID: 29,
};
